import React from 'react';

import classes from './Intro.module.css';

const intro = (props) => {
    
    return (
        <div className={classes.Intro}>
            <div className={classes.IntroContent}>
            <p><b>IRREGULAR-VERBS</b> is a web application designed for self-learning purposes - to learn and practise English irregular verbs.</p>
            <p>Please click "START" to begin.</p>
            <div className={classes.IntroStartButton} onClick={props.clicked}>START</div>
            </div>
        </div>
    );
}

export default intro;
