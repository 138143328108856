import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Navigation.module.css';
import CloseButton from '../../UI/CloseButton/CloseButton';

const navigation = ( props ) => (
    <div>
    <CloseButton close={props.clicked}/>
    <ul className={classes.NavigationUL}>
        <li>
            <NavLink to="/full-list" exact vlist={props.vlist} onClick={props.animUp}>Full List</NavLink>
        </li>
        {/* <li>
            <NavLink to="/single" exact vlist={props.vlist}>Single Verb</NavLink>
        </li> */}
        <li>
            <NavLink to="/practise" exact vlist={props.vlist}>Practise</NavLink>
        </li>
        <li>
            <NavLink to="/tech-desc" exact onClick={props.animUp}>Tech description</NavLink>
        </li>
        {/* <li>
            <NavLink to="/dev" exact vlist={props.vlist}>Practise-DEV</NavLink>
        </li> */}
    </ul>
    </div>

);

export default navigation;