import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import './App.css';
import busTop from './assets/images/bustop.gif';
import busBottom from './assets/images/busbottom.gif';
import {verbsListBase} from './data/irregular-verbs-base';

import Layout from './hoc/Layout';
import FullList from './components/FullList';
import Practise from './components/Practise';
import Intro from './components/Intro';
import TechDesc from './components/TechDesc';


class App extends Component {
  state = {
    verbsList: verbsListBase,
    currentVerb: null,
    cssClasses: "busbottom"
  }
  
  currentVerbHandler() {
    const keyArray = [];

    for (let key in this.state.verbsList) {
        keyArray.push(key*1);
    }
    // console.log(keyArray);

    let randomVerb = Math.round(Math.random() * keyArray.length) ;
    
    console.log(randomVerb);
  }

  redirectHandler = () => {
    this.props.history.push('/practise');
    this.setState({cssClasses: "busbottom animatedDown"});
  }
  
  animUpHandler = () => {
  
    this.setState({cssClasses: "busbottom animatedUp"});
  }

  animDownHandler = () => {
    this.setState({cssClasses: "busbottom animatedDown"});
  };

  animResetHandler = () => {
    this.setState({cssClasses: "busbottom"});
  }



  render() {
    // console.log(this.state.verbsList);
    const routes = (
      <Switch>
         <Route path="/practise" render={props => <Practise vlist={this.state.verbsList} animUp={this.animUpHandler} animDown={this.animDownHandler} animReset={this.animResetHandler}/>} />
         <Route path="/full-list" render={props => <FullList vlist={this.state.verbsList} close={this.redirectHandler} />} />
         <Route path="/tech-desc" render={props => <TechDesc close={this.redirectHandler} />} />
         <Route path="/" exact render={props => <Intro vlist={this.state.verbsList} clicked={this.redirectHandler}/>} />
         <Route path="/*" render={props => <Intro vlist={this.state.verbsList} clicked={this.redirectHandler}/>} />
         <Redirect to="/" />
       </Switch>
    );

      return (
      <div className="App" vlist={this.state.verbsList}>
        <div className="bustop">
          <h1 className="appname">Irregular verbs</h1>
          <img src={busTop} alt="Bus top"/>
        </div>  
        <Layout>
          {routes}
        </Layout>
        <div className={this.state.cssClasses}>
          <img src={busBottom} alt="Bus bottom"/>
        </div>

      </div>
    );
  }
  
}

export default withRouter(App);
