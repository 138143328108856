import React from 'react';

import classes from './TechDesc.module.css';
import CloseButton from '../UI/CloseButton/CloseButton';

const techDesc = (props) => {

    return (
      <div className={classes.TechDesc} >
        <div className={classes.Header}>
          <CloseButton close={props.close}/>
          <h2>Technical description</h2>
        </div>
        <div className={classes.Content}>
          <p><b>IRREGULAR-VERBS</b> is a web application designed in ReactJS with the usage of classic class based, “stateful” components and functional ones combined with hooks. My main, personal goal was to practice the functionalities of the library and additional packages. In the near future I will add features that allow the user to login and save the progress. Furthermore, I’m planning to move some of the state control to Redux.</p>
          <p>Please, visit my personal webpage:</p>
          <a href="https://e-ines.com" target="_blank" rel="noreferrer">e-ines</a>
          <p>Enjoy the app!!!</p>
        </div>
      </div>
    );
};

export default techDesc;