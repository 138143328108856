import React from 'react';

import classes from './SideDrawer.module.css';
import Navigation from '../Navigation';

const sideDrawer = (props) => {
    let cssClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        cssClasses = [classes.SideDrawer, classes.Open];
    }
    return(
        <div>
             
            <div className={cssClasses.join(' ')} onClick={props.closed}>
                 <nav>
                    <Navigation animUp={props.animUp} clicked={props.clicked}/>
                </nav>
            </div>
        </div>
    );
};

export default sideDrawer;