import React from 'react';

import classes from './CloseButton.module.css';

const closeButton = (props) => {
    return (
        <div className={classes.CloseButton} onClick={props.close}>
            
            <div className={classes.First}></div>
            <div  className={classes.Second}></div>
            <div className={classes.Round}/>
        </div>
    );

};

export default closeButton;