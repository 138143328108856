import React from 'react';
import CloseButton from '../UI/CloseButton/CloseButton';

import classes from './FullList.module.css';

const fullList = (props) => {
    // const verbsList = props.vlist;

    const verbsOutput = props.vlist.map((verb) => (
      <tr id={verb.base} key={verb.base}>
        <td>{verb.base}</td>
        <td>{verb.pastS}{verb.pastSbis ? `/${verb.pastSbis}` : null}</td>
        <td>{verb.pastP}{verb.pastPbis ? `/${verb.pastPbis}` : null}</td>
      </tr>
    ));

    return (

        <div className={classes.FullList}>
            <div className={classes.Header}>
                <CloseButton close={props.close}/>
                <h2>Full List of Verbs</h2>
            </div>
            <table>
            <tbody>{verbsOutput}</tbody> 
            </table>
      </div>
    );
};

export default fullList;