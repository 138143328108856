import React, { useEffect, useState, useRef } from 'react';

import classes from './Practise.module.css';
import ToggleDrawer from './Navigation/ToggleDrawer/ToggleDrawer';
import SideDrawer from './Navigation/SideDrawer/SideDrawer';

const Practise = (props) => {

// *** "INITIAL STATE"
    const [psInputValue, setPSInputValue] = useState('');
    const [ppInputValue, setPPInputValue] = useState('');
    const [randomVerb, setRandomVerb] = useState(0);
    const [randomCongrats, setRandomCongrats] = useState(0);
    const [showCorrectForms, setShowCorrectForms] = useState(false);
    const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
    const [drawButtonClicked, setDrawButtonClicked] = useState(false);
    // const [currentOpenState] = useState(!sideDrawerOpen);

// ***MAIN DATA VARIABLES***
    const keyArray = [];
    for (let key in props.vlist) {
        keyArray.push(key*1);
    }
    const keyArrayLength = keyArray.length;

    const congratsArray = ['CORRECT!!!', 'YOU ARE SIMPLY PERFECT!!!', 'YOU MADE IT!!!', 'AWESOME!!!', 'GREAT JOB!!!', 'YEAP!!!', 'YOU ROCK!!!'];
    const congratsArrayLength = congratsArray.length;

        // REFs:
    const psInput = useRef();
    const ppInput = useRef();
    const drawButton = useRef();




// LIFECYCLE METHODS:
    useEffect(() => {
        setRandomVerb(Math.floor(Math.random() * keyArrayLength));
        setRandomCongrats(Math.floor(Math.random() * congratsArrayLength));
    }, [keyArrayLength, congratsArrayLength]);

    useEffect(() => {
        if (psInputValue === props.vlist[randomVerb].pastS) {
                ppInput.current.focus(); 
        }
        if (ppInputValue === props.vlist[randomVerb].pastP) {
                    psInput.current.focus(); 
        }
        if (  (psInputValue === props.vlist[randomVerb].pastSbis || psInputValue === props.vlist[randomVerb].pastS) &&
        (ppInputValue === props.vlist[randomVerb].pastPbis || ppInputValue === props.vlist[randomVerb].pastP)) {
            drawButton.current.focus();
        }
    },[props.vlist, psInputValue, ppInputValue, randomVerb]);


// ***HANDLERS***
    const pastSimpleInputHandler = (event) => {
        setPSInputValue((event.target.value).toLowerCase());
    };

    const pastPerfectInputHandler = (event) => {
        setPPInputValue((event.target.value).toLowerCase());
    };

    const showCorrectHandler = () => {
        setShowCorrectForms(true);
        props.animDown();
    };

    const drawVerb = () => {
        props.animUp();
        setShowCorrectForms(false);
        setDrawButtonClicked(true);
        setPSInputValue('');
        setPPInputValue('');
        setRandomVerb(Math.floor(Math.random() * keyArrayLength));
        setRandomCongrats(Math.floor(Math.random() * congratsArrayLength));
    };

    const sideDrawerClosedHandler = () => {
        setSideDrawerOpen(false);
    }

    const toggleDrawer = () => {
        setSideDrawerOpen(!sideDrawerOpen);
    }
    
// ***ANIMATIONS***
        // CSS:
let cssSolutionClasses = classes.animatedDownP;

if(drawButtonClicked) {
    cssSolutionClasses = classes.animatedUpP;
}

let cssDrawClasses = [classes.Draw, classes.animatedDownP];
if(showCorrectForms) {
    cssDrawClasses = [classes.Draw, classes.animatedUpP];
}



// ***VERBS OUTPUT***
    const baseVerb = props.vlist[randomVerb].base;
    const pastSVerb = props.vlist[randomVerb].pastS;
    const pastPVerb = props.vlist[randomVerb].pastP;
    const pastSVerbBis =  props.vlist[randomVerb].pastSbis;
    const pastPVerbBis =  props.vlist[randomVerb].pastPbis;

    const baseVerbOutput = props.vlist[randomVerb].base;

    let pastSVerbOutput = <td><input ref={psInput} type="text" autoFocus placeholder="past simple" value={psInputValue} onChange={pastSimpleInputHandler}/></td>; 
    if (psInputValue === pastSVerb) {
        pastSVerbOutput =  <td ref={psInput}>{pastSVerb}</td>;  
    }

    let pastPVerbOutput = <td><input ref={ppInput} type="text" placeholder="past perfect" value={ppInputValue} onChange={pastPerfectInputHandler}/></td>; 
    if (ppInputValue === pastPVerb) {
        pastPVerbOutput =  <td ref={ppInput}>{pastPVerb}</td>;
    }

// ***CORRECT FORMS OUTPUT***
    let correctForms = '';
    if(showCorrectForms) {
        correctForms = (
            <table className={cssSolutionClasses}>
            <tbody>
                <tr key={baseVerb}>
                    <td>{baseVerb}</td>
                    <td>{pastSVerb}{pastSVerbBis ? `/${pastSVerbBis}`: null}</td>
                    <td>{pastPVerb}{pastPVerbBis ? `/${pastPVerbBis}` : null}</td>
                </tr>
            </tbody>
            </table>
        );
    }

// ***CONGRATULATIONS OUTPUT***
let congratsOutput = '';
if (
  (psInputValue === pastSVerbBis || psInputValue === pastSVerb) &&
  (ppInputValue === pastPVerbBis || ppInputValue === pastPVerb) &&
  !showCorrectForms
) {congratsOutput = <h2 style={{color: "#00a86b"}}>{congratsArray[randomCongrats]}</h2>;}

// ***ADDITIONAL INFO FOR VERBS WITH TWO CORRECT FORMS OUTPUT (will be displayed together with congrats)***
    let addInfoPS = '';
    if (pastSVerbBis && congratsOutput) {
        addInfoPS = <div style={{margin: "10px auto" }}>There are two correct past simple forms: "{pastSVerb}" and "{pastSVerbBis}"</div>;
    }

    let addInfoPP = '';
    if (pastPVerbBis && congratsOutput) {
        addInfoPP = <div style={{margin: "10px auto"}}>There are two correct past perfect forms: "{pastPVerb}" and "{pastPVerbBis}"</div>;
    }

//***COMPONENT OUTPUT***
    return (   
        
      <div className={classes.Practise} >
            <ToggleDrawer clicked={toggleDrawer} />
            <SideDrawer
                clicked={toggleDrawer}
                open={sideDrawerOpen}
                closed={sideDrawerClosedHandler} 
                animUp={props.animUp}
            />

            <h2>Practise</h2>
            <table>
            <tbody>
                <tr key={baseVerb}>
                    <td>{baseVerbOutput}</td>
                    {pastSVerbOutput}
                    {pastPVerbOutput}
                </tr>
            </tbody>
            </table>
            {congratsOutput}
            <br/>
            {addInfoPS}
            {addInfoPP} 
            <button onClick={showCorrectHandler} disabled={showCorrectForms}>show solution</button>
            {correctForms}
            <br/>
            <button className={cssDrawClasses.join(' ')} ref={drawButton} onClick={drawVerb}>DRAW NEXT VERB</button>
      </div>
    );
};

export default Practise;