export const verbsListBase = [{ 
	"base" : "arise", 		
	"pastS" :	"arose",
	"pastP" : "arisen",
    "chck": false
},
{ 
	"base" : "awake", 		
	"pastS" :	"awoke",
    "pastP" : "awoken",
    "chck": false

},
{ 
	"base" : "be", 		
	"pastS" : "was",
	"pastSbis": "were",
	"pastP" : "been",
    "chck": false

},
{ 
	"base" : "bear", 		
	"pastS" : "bore",
	"pastP" : "born",
	"pastPbis" : "borne",
    "chck": false

},
{ 
	"base" : "beat", 		
	"pastS" : "beat",
	"pastP" : "beaten",
    "chck": false

},
{ 
	"base" : "become", 		
	"pastS" : "became",
	"pastP" : "become",
    "chck": false

},
{ 
	"base" : "begin", 		
	"pastS" : "began",
	"pastP" : "begun",
    "chck": false

},
{ 
	"base" : "bend", 		
	"pastS" : "bent",
	"pastP" : "bent",
    "chck": false

},
{ 
	"base" : "bet", 		
	"pastS" : "bet",
	"pastP" : "bet",
    "chck": false

},
{ 
	"base" : "bind", 		
	"pastS" : "bound",
	"pastP" : "bound",
    "chck": false

},
{ 
	"base" : "bite", 		
	"pastS" : "bit",
	"pastP" : "bitten",
    "chck": false

},
{ 
	"base" : "bleed", 		
	"pastS" : "bled",
	"pastP" : "bled",
    "chck": false

},
{ 
	"base" : "blow", 		
	"pastS" : "blew",
	"pastP" : "blown",
    "chck": false

},
{ 
	"base" : "break", 		
	"pastS" : "broke",
	"pastP" : "broken",
    "chck": false

},
{ 
	"base" : "breed", 		
	"pastS" : "bred",
	"pastP" : "bred",
    "chck": false

},
{ 
	"base" : "bring", 		
	"pastS" : "brought",
	"pastP" : "brought",
    "chck": false

},
{ 
	"base" : "broadcast", 		
	"pastS" : "broadcast",
	"pastP" : "broadcast",
    "chck": false

},
{ 
	"base" : "build", 		
	"pastS" : "built",
	"pastP" : "built",
    "chck": false

},
{ 
	"base" : "burn", 		
	"pastS" : "burnt",
	"pastSbis" : "burned",
	"pastP" : "burnt",
	"pastPbis" : "burned",
    "chck": false

},
{ 
	"base" : "burst", 		
	"pastS" : "burst",
	"pastP" : "burst",
    "chck": false

},
{ 
	"base" : "buy", 		
	"pastS" : "bought",
	"pastP" : "bought",
    "chck": false

},
{ 
	"base" : "can", 		
	"pastS" : "could",
	"pastP" : "been able",
    "chck": false

},
{ 
	"base" : "catch", 		
	"pastS" : "caught",
	"pastP" : "caught",
    "chck": false

},
{ 
	"base" : "choose", 		
	"pastS" : "chose",
	"pastP" : "chosen",
    "chck": false

},
{ 
	"base" : "cling", 		
	"pastS" : "clung",
	"pastP" : "clung",
    "chck": false

},
{ 
	"base" : "come", 		
	"pastS" :	"came",
	"pastP" : "come",
    "chck": false

},
{ 
	"base" : "cost", 		
	"pastS" :	"cost",
	"pastP" : "cost",
    "chck": false

},
{ 
	"base" : "creep", 		
	"pastS" :	"crept",
	"pastP" : "crept",
    "chck": false

},
{ 
	"base" : "cut", 		
	"pastS" :	"cut",
	"pastP" : "cut",
    "chck": false

},
{ 
	"base" : "deal", 		
	"pastS" :	"dealt",
	"pastP" : "dealt",
    "chck": false

},
{ 
	"base" : "dig", 		
	"pastS" :	"dug",
	"pastP" : "dug",
    "chck": false

},
{ 
	"base" : "do", 		
	"pastS" :	"did",
	"pastP" : "done",
    "chck": false

},
{ 
	"base" : "draw", 		
	"pastS" :	"drew",
	"pastP" : "drawn",
    "chck": false

},
{ 
	"base" : "dream", 		
	"pastS" : "dreamt",
	"pastSbis" : "dreamed",
	"pastP" : "dreamt",
	"pastPbis" : "dreamed",
    "chck": false

},
{ 
	"base" : "drink", 		
	"pastS" :	"drank",
	"pastP" : "drunk",
    "chck": false

},
{ 
	"base" : "drive", 		
	"pastS" :	"drove",
	"pastP" : "driven",
    "chck": false

},
{ 
	"base" : "eat", 		
	"pastS" :	"ate",
	"pastP" : "eaten",
    "chck": false

},
{ 
	"base" : "fall", 		
	"pastS" :	"fell",
	"pastP" : "fallen",
    "chck": false

},
{ 
	"base" : "feed", 		
	"pastS" :	"fed",
	"pastP" : "fed",
    "chck": false

},
{ 
	"base" : "feel", 		
	"pastS" :	"felt",
	"pastP" : "felt",
    "chck": false

},
{ 
	"base" : "fight", 		
	"pastS" :	"fought",
	"pastP" : "fought",
    "chck": false

},
{ 
	"base" : "find", 		
	"pastS" :	"found",
	"pastP" : "found",
    "chck": false

},
{ 
	"base" : "fly", 		
	"pastS" :	"flew",
	"pastP" : "flown",
    "chck": false

},
{ 
	"base" : "forbid", 		
	"pastS" :	"forbade",
	"pastP" : "forbidden",
    "chck": false

},
{ 
	"base" : "forget", 		
	"pastS" :	"forgot",
	"pastP" : "forgotten",
    "chck": false

},
{ 
	"base" : "forgive", 		
	"pastS" :	"forgave",
	"pastP" : "forgiven",
    "chck": false

},
{ 
	"base" : "freeze", 		
	"pastS" :	"froze",
	"pastP" : "frozen",
    "chck": false

},
{ 
	"base" : "get", 		
	"pastS" :	"got",
	"pastP" : "got",
    "chck": false

},
{ 
	"base" : "give", 		
	"pastS" :	"gave",
	"pastP" : "given",
    "chck": false

},
{ 
	"base" : "go", 		
	"pastS" :	"went",
	"pastP" : "gone",
    "chck": false

},
{ 
	"base" : "grind", 		
	"pastS" :	"ground",
	"pastP" : "ground",
    "chck": false

},
{ 
	"base" : "grow", 		
	"pastS" :	"grew",
	"pastP" : "grown",
    "chck": false

},
{ 
	"base" : "hang", 		
	"pastS" :	"hung",
	"pastP" : "hung",
    "chck": false

},
{ 
	"base" : "have", 		
	"pastS" :	"had",
	"pastP" : "had",
    "chck": false

},
{ 
	"base" : "hear", 		
	"pastS" :	"heard",
	"pastP" : "heard",
    "chck": false

},
{ 
	"base" : "hide", 		
	"pastS" :	"hid",
	"pastP" : "hidden",
    "chck": false

},
{ 
	"base" : "hit", 		
	"pastS" :	"hit",
	"pastP" : "hit",
    "chck": false

},
{ 
	"base" : "hold", 		
	"pastS" :	"held",
	"pastP" : "held",
    "chck": false

},
{ 
	"base" : "hurt", 		
	"pastS" :	"hurt",
	"pastP" : "hurt",
    "chck": false

},
{ 
	"base" : "keep", 		
	"pastS" :	"kept",
	"pastP" : "kept",
    "chck": false

},
{ 
	"base" : "kneel", 		
	"pastS" :	"knelt",
	"pastP" : "knelt",
    "chck": false

},
{ 
	"base" : "know", 		
	"pastS" :	"knew",
	"pastP" : "known",
    "chck": false

},
{ 
	"base" : "lay", 		
	"pastS" :	"laid",
	"pastP" : "laid",
    "chck": false

},
{ 
	"base" : "lead", 		
	"pastS" :	"led",
	"pastP" : "led",
    "chck": false

},
{ 
	"base" : "lean", 		
	"pastS" :"leant",
	"pastSbis" :"leaned",
	"pastP" : "leant",
	"pastPbis" :"leaned",
    "chck": false

},
{ 
	"base" : "learn", 		
	"pastS" : "learnt",
	"pastSbis" : "learned",
	"pastP" : "learnt",
	"pastPbis" : "learned",
    "chck": false

},
{ 
	"base" : "leave", 		
	"pastS" :	"left",
	"pastP" : "left",
    "chck": false

},
{ 
	"base" : "lent", 		
	"pastS" :	"lent",
	"pastP" : "lent",
    "chck": false

},
{ 
	"base" : "lie (in bed)", 		
	"pastS" :	"lay",
	"pastP" : "lain",
    "chck": false

},
{ 
	"base" : "lie (to not tell the truth)", 		
	"pastS" : 	"lied",
	"pastP" : "lied",
    "chck": false

},
{ 
	"base" : "light", 		
	"pastS" : "lit",
	"pastSbis" : "lighted",
	"pastP" : "lit",
	"pastPbis" : "lighted",
    "chck": false

},
{ 
	"base" : "lose", 		
	"pastS" :	"lost",
	"pastP" : "lost",
    "chck": false

},
{ 
	"base" : "make", 		
	"pastS" :	"made",
	"pastP" : "made",
    "chck": false

},
{ 
	"base" : "may", 		
	"pastS" :	"might",
	"pastP" : "",
    "chck": false

},
{ 
	"base" : "mean", 		
	"pastS" :	"meant",
	"pastP" : "meant",
    "chck": false

},
{ 
	"base" : "meet", 		
	"pastS" :	"met",
	"pastP" : "met",
    "chck": false

},
{ 
	"base" : "mow", 		
	"pastS" :	"mowed",
	"pastP" : "mown",
	"pastPbis" : "mowed",
    "chck": false

},
{ 
	"base" : "must", 		
	"pastS" :	"had to",
	"pastP" : "",
    "chck": false

},
{ 
	"base" : "overtake", 		
	"pastS" :	"overtook",
	"pastP" : "overtaken",
    "chck": false

},
{ 
	"base" : "pay", 		
	"pastS" :	"paid",
	"pastP" : "paid",
    "chck": false

},
{ 
	"base" : "put", 		
	"pastS" :	"put",
	"pastP" : "put",
    "chck": false

},
{ 
	"base" : "read", 		
	"pastS" :	"read",
	"pastP" : "read",
    "chck": false

},
{ 
	"base" : "ride", 		
	"pastS" :	"rode",
	"pastP" : "ridden",
    "chck": false

},
{ 
	"base" : "ring", 		
	"pastS" :	"rang",
	"pastP" : "rung",
    "chck": false

},
{ 
	"base" : "rise", 		
	"pastS" :	"rose",
	"pastP" : "risen",
    "chck": false

},
{ 
	"base" : "run", 		
	"pastS" :	"ran",
	"pastP" : "run",
    "chck": false

},
{ 
	"base" : "saw", 		
	"pastS" :	"sawed",
	"pastP" : "sawn",
	"pastPbis" : "sawed",
    "chck": false

},
{ 
	"base" : "say", 		
	"pastS" :	"said",
	"pastP" : "said",
    "chck": false

},
{ 
	"base" : "see", 		
	"pastS" :	"saw",
	"pastP" : "seen",
    "chck": false
},
{ 
	"base" : "sell", 		
	"pastS" :	"sold",
	"pastP" : "sold",
    "chck": false

},
{ 
	"base" : "send", 		
	"pastS" :	"sent",
	"pastP" : "sent",
    "chck": false

},
{ 
	"base" : "set", 		
	"pastS" :	"set",
	"pastP" : "set",
    "chck": false

},
{ 
	"base" : "sew", 		
	"pastS" :	"sewed",
	"pastP" : "sewn",
	"pastPbis" : "sewed",
    "chck": false

},
{ 
	"base" : "shake", 		
	"pastS" :	"shook",
	"pastP" : "shaken",
    "chck": false

},
{ 
	"base" : "shall", 		
	"pastS" :	"should",
	"pastP" : "",
    "chck": false

},
{ 
	"base" : "shed", 		
	"pastS" :	"shed",
	"pastP" : "shed",
    "chck": false

},
{ 
	"base" : "shine", 		
	"pastS" :	"shone",
	"pastP" : "shone",
    "chck": false

},
{ 
	"base" : "shoot", 		
	"pastS" :	"shot",
	"pastP" : "shot",
    "chck": false

},
{ 
	"base" : "show", 		
	"pastS" :	"showed",
	"pastP" : "shown",
	"pastPbis" : "showed",
    "chck": false

},
{ 
	"base" : "shrink", 		
	"pastS" :	"shrank",
	"pastP" : "shrunk",
    "chck": false

},
{ 
	"base" : "shut", 		
	"pastS" :	"shut",
	"pastP" : "shut",
    "chck": false

},
{ 
	"base" : "sing", 		
	"pastS" :	"sang",
	"pastP" : "sung",
    "chck": false

},
{ 
	"base" : "sink", 		
	"pastS" :	"sank",
	"pastP" : "sunk",
    "chck": false

},
{ 
	"base" : "sit", 		
	"pastS" :	"sat",
	"pastP" : "sat",
    "chck": false

},
{ 
	"base" : "sleep", 		
	"pastS" :	"slept",
	"pastP" : "slept",
    "chck": false

},
{ 
	"base" : "slide", 		
	"pastS" :	"slid",
	"pastP" : "slid",
    "chck": false

},
{ 
	"base" : "smell", 		
	"pastS" :	"smelt",
	"pastP" : "smelt",
    "chck": false

},
{ 
	"base" : "sow", 		
	"pastS" :	"sowed",
	"pastP" : "sown",
	"pastPbis" : "sowed",
    "chck": false

},
{ 
	"base" : "speak", 		
	"pastS" :	"spoke",
	"pastP" : "spoken",
    "chck": false

},
{ 
	"base" : "spell", 		
	"pastS" : "spelt",
	"pastSbis" : "spelled",
	"pastP" : "spelt",
	"pastPbis" : "spelled",
    "chck": false

},
{ 
	"base" : "spend", 		
	"pastS" :	"spent",
	"pastP" : "spent",
    "chck": false

},
{ 
	"base" : "spill", 		
	"pastS" : "spilt",
	"pastSbis" : "spilled",
	"pastP" : "spilt",
	"pastPbis" : "spilled",
    "chck": false

},
{ 
	"base" : "spit", 		
	"pastS" :	"spat",
	"pastP" : "spat",
    "chck": false

},
{ 
	"base" : "spread", 		
	"pastS" :	"spread",
	"pastP" : "spread",
    "chck": false

},
{ 
	"base" : "stand", 		
	"pastS" :	"stood",
	"pastP" : "stood",
    "chck": false

},
{ 
	"base" : "steal", 		
	"pastS" :	"stole",
	"pastP" : "stolen",
    "chck": false

},
{ 
	"base" : "stick", 		
	"pastS" :	"stuck",
	"pastP" : "stuck",
    "chck": false

},
{ 
	"base" : "sting", 		
	"pastS" :	"stung",
	"pastP" : "stung",
    "chck": false

},
{ 
	"base" : "stink", 		
	"pastS" :	"stank",
	"pastP" : "stunk",
    "chck": false

},
{ 
	"base" : "strike", 		
	"pastS" :	"struck",
	"pastP" : "struck",
    "chck": false

},
{ 
	"base" : "swear", 		
	"pastS" :	"swore",
	"pastP" : "sworn",
    "chck": false

},
{ 
	"base" : "sweep", 		
	"pastS" :	"swept",
	"pastP" : "swept",
    "chck": false

},
{ 
	"base" : "swell", 		
	"pastS" :	"swelled",
	"pastP" : "swollen",
	"pastPbis" : "swelled",
    "chck": false

},
{ 
	"base" : "swim", 		
	"pastS" :	"swam",
	"pastP" : "swum",
    "chck": false

},
{ 
	"base" : "swing", 		
	"pastS" :	"swung",
	"pastP" : "swung",
    "chck": false

},
{ 
	"base" : "take", 		
	"pastS" :	"took",
	"pastP" : "taken",
    "chck": false

},
{ 
	"base" : "teach", 		
	"pastS" :	"taught",
	"pastP" : "taught",
    "chck": false

},
{ 
	"base" : "tear", 		
	"pastS" :	"tore",
	"pastP" : "torn",
    "chck": false

},
{ 
	"base" : "tell", 		
	"pastS" :	"told",
	"pastP" : "told",
    "chck": false

},
{ 
	"base" : "think", 		
	"pastS" :	"thought",
	"pastP" : "thought",
    "chck": false

},
{ 
	"base" : "throw", 		
	"pastS" :	"threw",
	"pastP" : "thrown",
    "chck": false
},
{ 
	"base" : "understand", 		
	"pastS" :	"understood",
	"pastP" : "understood",
    "chck": false

},
{ 
	"base" : "wake", 		
	"pastS" :	"woke",
	"pastP" : "woken",
    "chck": false

},
{ 
	"base" : "wear", 		
	"pastS" :	"wore",
	"pastP" : "worn",
    "chck": false

},
{ 
	"base" : "weep", 		
	"pastS" :	"wept",
	"pastP" : "wept",
    "chck": false

},
{ 
	"base" : "will", 		
	"pastS" :	"would",
	"pastP" : "",
    "chck": false

},
{ 
	"base" : "win", 		
	"pastS" :	"won",
	"pastP" : "won",
    "chck": false

},
{ 
	"base" : "wind", 		
	"pastS" :	"wound",
	"pastP" : "wound",
    "chck": false

},
{ 
	"base" : "write", 		
	"pastS" :	"wrote",
	"pastP" : "written",
    "chck": false

}];